import { useRouter } from 'next/router'

import { GlobalLoading } from '@/components/molecules/GlobalLoading'

import type { NextPageWithLayout } from '@/types/app'

const Home: NextPageWithLayout = () => {
  const router = useRouter()
  router.replace(`/information`)

  return <GlobalLoading />
}

export default Home
